import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const QuestionsAndAnswers = [
    {
        "question": "Is Irishman Cloud looking for investors or funding right now?",
        "answer": "The Irishman team knows how valuable the project and its underlying intellectual property really is. We would be happy to work with investors as a means to accelerate our growth, but the most important factor for us is finding a good match. If you are an investor and feel that you are a good match, please feel free to contact us by email with a subject line prefix of 'Investor:' followed by the company you represent."
    },
    {
        "question": "What type of company is Irishman Cloud, Inc.?",
        "answer": "Legally speaking, 'Irishman Cloud, Inc.' is a C-Corporation registered in Delaware, a state of the United States of America. We are a privately owned company with a number of shares devoted to stock-options for our strongest employees. Because of this structure, we can dilute shares to welcome on new investors, organize subsidiaries with relative simplicity, and even purchase and merge with other companies."
    },
    {
        "question": "I'm a developer that knows XYZ, is there a job at Irishman Cloud for that?",
        "answer": "We are looking for highly skilled developers that focus on building modular code that supports itself. We believe in 'punching up' with our workforce, or, in layman's terms, having our workers automate boring tasks so that we can operate at a higher level overall. If you believe that you are one of these people, and share similar beliefs to our company values, feel free to contact us via email with your resume and a subject line starting with 'Job Applicant:'. If you do this, we will get back to you within two business weeks on how we wish to move forward."
    },
    {
        "question": "Can you support [Insert Programming Language Name Here]?",
        "answer": "Sure! If you have a programming language that you would like us to add to our roster, feel free to send us an email with the name of the programming language and some information about it. But, we do have a few caveats about which programming languages we support. 1) The language must be popular. By this, we mean that there should be substantial usage of the language on websites like Stack Overflow, Wikipedia, GeeksForGeeks, etc. 2) We only support languages that are likely to be used in production. We don't want to devote precious hours to supporting languages that won't be used professionally, like Brainf*ck, Shakespeare, or any other esoteric languages. 3) The language must be text based. This one is pretty self explanatory (sorry piet). If the language you want fits all of these guidelines, feel free to ask us about supporting it! We will try to add it as soon as we can."
    }
];

const DetailsGroup = ({ question, answer }) => (
    <details class="group">
        <summary
            class="flex cursor-pointer items-center justify-between rounded-lg bg-gray-50 p-4"
        >
            <h2 class="font-medium text-gray-900">
                {question}
            </h2>

            <svg
                class="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                />
            </svg>
        </summary>

        <p class="mt-4 px-4 leading-relaxed text-gray-700">
            {answer}
        </p>
    </details>
)

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData.filter(({ }, i) => i < 6);

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        Frequently Asked Questions
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        If you have a question about the Irishman Cloud that isn't in the our About or History pages, you should be able to find it below.
                    </p>
                </div>
            </div>
        </section>
        <section>
            <div class="space-y-4 mx-auto max-w-screen-xl px-4 py-32 my-16">
                {
                    QuestionsAndAnswers.map(data => DetailsGroup(data))
                }
            </div>
        </section>
    </Layout >
}

export const Head = () => <Seo title="Frequently Asked Questions" children={<>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": QuestionsAndAnswers.map(QA => ({
                "@type": "Question",
                "name": QA.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": QA.answer
                }
            }))
        })
    }} />
</>} />

export default IndexPage
